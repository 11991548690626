import React, { useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import qs from 'qs';
import axios from 'axios';
import classNames from 'classnames';
import usePlatform from '../../hooks/usePlatform';
import { Portal } from 'react-portal';
import { useLocaleString, useTexts } from '@devsisters/gatsby-plugin-i18n';
import { CookiesLoadingIndicator } from '@devsisters/ui-common/lib/loading-indicator';

interface CouponForm { }

function getTime() {
  return new Date().getTime();
}

function easeInOutCubic(t: number) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

function filterValue(value: string) {
  return value.replace(/\s|\W|_/g, '').toUpperCase();
}

interface Response {
  too_many_err: boolean;
  expired: boolean;
  already_used: boolean;
  global_limit_overed: boolean;
  use_limit_over: number;
  error_msg: string;
}

const CouponForm: React.FC<CouponForm> = ({ }) => {
  const recaptchaSiteKey = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY;
  if (!recaptchaSiteKey) {
    throw new Error('GATSBY_GOOGLE_RECAPTCHA_SITE_KEY 환경변수가 없습니다');
  }
  const t = useTexts();
  const lang = useLocaleString();
  const device = usePlatform();
  const isMobile = device === 'mobile';
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [coupon, setCoupon] = useState('');
  const check = useRef<HTMLDivElement>(null);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (!id) {
      return alert(t`cp_alert_id`);
    }

    if (id.length !== 9) {
      return alert(t`cp_alert_nine`);
    }

    if (!coupon) {
      return alert(t`cp_alert_coupon_number`);
    }

    if (coupon.length !== 16) {
      return alert(t`cp_alert_coupon_16`);
    }

    setLoading(true);

    try {
      let token: string = '';

      if (!window.grecaptcha) {
        throw new Error('reCaptcha is not initialized properly');
      }
      await Promise.race([
        new Promise((_res, rej) => setTimeout(rej, 5000)),
        new Promise(res => window.grecaptcha?.ready(res)),
      ]);

      token = await window.grecaptcha.execute(recaptchaSiteKey, {
        action: 'submit',
      });

      const data = qs.stringify({
        mid: id,
        coupon_code: coupon,
        combo_name: 'dc_coupon',
        recaptcha_token: token,
      });

      const payload = await axios.post<Response>(
        'https://coupon.devsgb.com/coupon/use',
        data,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (payload.status === 200) {
        alert(t`cp_alert_success`);
      }
    } catch (e) {
      if (!e || !e.response) {
        alert(t`cp_alert_use_ingame`);
      } else {
        console.log(e);
        const { already_used, expired, use_limit_over, too_many_err, global_limit_overed } = e.response
          .data as Response;

        if (already_used) {
          alert(t`cp_alert_used`);
        } else if (expired) {
          alert(t`cp_alert_expired`);
        } else if (use_limit_over) {
          alert(t`cp_alert_duplication`);
        } else if (too_many_err) {
          alert(t`cp_alert_too_many_retry`);
        } else if (global_limit_overed) {
          alert(t`cp_alert_global_limit_overed`);
        } else {
          alert(t`cp_alert_try_again`);
        }
      }
    }

    setLoading(false);
  };

  return (
    <div className={classNames(styles.CouponForm, isMobile && styles.isMobile)}>
      <div className={styles.banner}>
        <img src={require(`../../images/coupon/${lang}/top.jpg`)} />
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={classNames(styles.input, styles.id)}>
          <img
            className={styles.title}
            src={require(`../../images/coupon/${lang}/${device}/id_text.png`)}
          />
          <img
            className={styles.information}
            src={require(`../../images/coupon/${lang}/${device}/id_info.png`)}
            onClick={() => {
              if (check.current) {
                let frameHandle: null | number;
                const { top } = check.current.getBoundingClientRect();

                const currentY = window.scrollY;
                const duration = 1000;
                const startTime = getTime();
                const frame = () => {
                  frameHandle = window.requestAnimationFrame(frame);
                  const animate = (getTime() - startTime) / duration;
                  if (animate > 1 && frameHandle !== null) {
                    window.cancelAnimationFrame(frameHandle);
                  }

                  const easing = easeInOutCubic(animate);
                  const y = currentY + top * easing;

                  window.scrollTo(0, y);
                };

                frameHandle = window.requestAnimationFrame(frame);
              }
            }}
          />
          <label className={styles.label}>
            <input
              type="text"
              maxLength={9}
              placeholder={t`cp_placeholder_user_id`}
              value={id}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setId(filterValue(e.currentTarget.value));
              }}
            />
          </label>
        </div>
        <div className={classNames(styles.input, styles.coupon)}>
          <img
            className={styles.title}
            src={require(`../../images/coupon/${lang}/${device}/coupon_text.png`)}
          />
          <label className={styles.label}>
            <input
              type="text"
              maxLength={16}
              placeholder={t`cp_placeholder_coupon_number`}
              value={coupon}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setCoupon(filterValue(e.currentTarget.value));
              }}
            />
          </label>
        </div>
        <div className={styles.action}>
          <button className={styles.submit} onClick={onSubmit}>
            <img
              src={require(`../../images/coupon/${lang}/${device}/btn.png`)}
            />
          </button>
        </div>
      </form>
      <div
        className={styles.additional}
        dangerouslySetInnerHTML={{
          __html: t`cp_info`,
        }}
      />
      <div ref={check} className={styles.check}>
        <div className={styles.footer}>
          <img
            src={require(`../../images/coupon/${lang}/${device}/dev_logo.png`)}
          />
          <p className={styles.copyright}>
            © DEVSISTERS Corp. All Rights Reserved
          </p>
        </div>
      </div>
      {loading && (
        <Portal>
          <div className={styles.dimmer} />
          <CookiesLoadingIndicator className={styles.loading} />
        </Portal>
      )}
    </div>
  );
};

export default CouponForm;
